import * as React from 'react';
import styled, { css } from 'styled-components';
import { IButtonProps } from './interfaces';
import { Color } from '../../helpers/Fonts';
import { FlexWrapper, FlexDirection, FlexWrap } from '../../helpers/FlexWrapper';
import { StyledSpinner } from '../Spinner';

const DISABLED_COLOR = Color.TEXT_GREY;

const BUTTON_SIZE = (props: IButtonProps) => {
  const CURRENT_SIZE = props.size || { width: '300px', height: '50px' };

  return css`
    width: ${CURRENT_SIZE.width};
    height: ${CURRENT_SIZE.height};
    border-radius: ${(props: IButtonProps) => props.borderRadius || '25px'};
  `;
};

const BUTTON_COLOR = (props: IButtonProps) => {
  const CURRENT_COLOR = props.color || Color.PRIMARY;

  return props.transparent
    ? css`
        border: 1px solid ${CURRENT_COLOR};
        background-color: transparent;
        color: ${CURRENT_COLOR};
      `
    : css`
        border: none;
        background-color: ${CURRENT_COLOR};
        color: #fff;
      `;
};

const BUTTON_OPACITY = ({ disabled }: IButtonProps) => {
  if (disabled) {
    return css`
      opacity: 0.6;
      cursor: default;
      background-color: transparent;
      border: 1px solid ${DISABLED_COLOR};
      color: ${DISABLED_COLOR};
    `;
  }

  return css`
    cursor: pointer;
  `;
};

const ButtonStylesCss = css<IButtonProps>`
  ${BUTTON_SIZE};
  ${BUTTON_COLOR};
  ${BUTTON_OPACITY};
  word-break: ${({ wordBreak }) => wordBreak};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  margin: ${({ margin }) => margin || '10px'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  outline: none;
  max-width: 100%;
  transition: transform 100ms;
  line-height: 0.9;
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:active {
        transform: scale(0.98);
      }
    `}

  ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
    `}
`;

const StyledButton = styled.button`
  ${ButtonStylesCss};
`;

export const ButtonLabel = styled.label`
  ${ButtonStylesCss};

  display: block;
  line-height: 50px;
`;

export const LoadingIndicator = styled(
  StyledSpinner as React.ComponentType<React.ComponentProps<typeof StyledSpinner>>
)`
  margin: 0;
  width: 16px;
  height: 16px;

  & .path {
    stroke: ${({ transparent, color, disabled }) => {
      if (disabled) return DISABLED_COLOR;

      return transparent ? color ?? Color.PRIMARY : Color.WHITE;
    }};
  }
`;

const ButtonWrapper: React.FunctionComponent<IButtonProps> = (props) => (
  <StyledButton {...props}>
    <span>
      <FlexWrapper
        wrap={FlexWrap.NOWRAP}
        direction={props.inverseIcon ? FlexDirection.ROW_REVERSE : FlexDirection.ROW}
        as={'span'}
      >
        {props.loading && !props.disabled ? (
          <LoadingIndicator
            viewBox="0 0 50 50"
            transparent={props.transparent}
            color={props.color}
            disabled={props.disabled}
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
          </LoadingIndicator>
        ) : (
          <>
            {props.icon}
            {props.children}
          </>
        )}
      </FlexWrapper>
    </span>
  </StyledButton>
);

export const Button = styled(ButtonWrapper)``;
export * from './UploadButton';
export * from './BackButton';
